<template>
  <layout class="feedback" :loading="loading">
    <div class="row">
      <div class="col-6">
        <h2 class="mb-4">Feedback list</h2>
      </div>
      <div class="col-6 text-right">
        <router-link to="/feedback" class="btn btn-primary">Send feedback</router-link>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body p-0">
            <table class="table mb-0" >
              <thead>
                <th style="width: 50px">#</th>
                <th>Feedback</th>
                <th>Status</th>
                <th>Reply</th>
                <th style="width: 150px">Created at</th>
              </thead>
              <tbody>
                <tr v-for="(item, index) in list" :key="item.id">
                  <td>{{index + 1}}</td>
                  <td>
                    <div v-html="item.content" v-linkified></div>
                  </td>
                  <td>
                    <span :class="`badge badge-pill badge-${status(item.status)}`">{{item.status}}</span>
                  </td>
                  <td>{{item.reply}}</td>
                  <td>{{ formatDate(item.created_at) }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<script>

import moment from 'moment';

/**
 * Editors component
 */
export default {
  data() {
    return {
      feedbacks: [],
    };
  },
  mounted() {
    this.$store.dispatch('feedback/getFeedbacks');
  },
  methods: {
    formatDate(date){
      return moment(date).format('DD.MM.YYYY');
    },
    status(value) {
      if (value === 'sent') {
        return 'secondary'
      } else if (value === 'active') {
        return 'primary'
      } else if (value === 'done') {
        return 'success'
      } else if (value === 'declined') {
        return 'danger'
      }
    }
  },
  computed: {
    list() {
      return this.$store.getters['feedback/feedbackList'];
    },
    loading() {
      return this.$store.state.feedback.loading;
    }
  }
};
</script>
<style>
.feedback table img {
  max-width: 200px;
}
table, thead, tbody {
  width: 100%;
}
</style>